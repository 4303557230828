import store from "@/store"
import {IData, IRoleSaveData} from "@/types"
import ApiService from "@/services/api_service"
import qs from "qs"

export default {

  createProject: function (): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "manage_project",
        project: 'new',
        tab: 'project_info',
      };
      ApiService.request({
        method: 'post',
        data: qs.stringify(params),
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  saveProject: function (attrs): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        ...attrs,
        action: "save_project",
        tab: 'project_info',
      };
      ApiService.request({
        method: 'post',
        data: qs.stringify(params),
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  toggleProjectActive: function (projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "close_project",
        id: projectId,
        tab: 'project_info',
      };
      ApiService.request({
        method: 'post',
        data: qs.stringify(params),
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  removeProject: function (projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "remove_project",
        id: projectId,
        tab: 'project_info',
      };
      ApiService.request({
        method: 'post',
        data: qs.stringify(params),
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  saveTicketSchema: function (projectId: string, schemaContent: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "save_ticket_schema",
        project_id: projectId || store.getters.project?.id,
        content: schemaContent,
      };
      ApiService.request({
        method: 'post',
        data: qs.stringify(params),
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  saveProjectTeamRole: function (projectId: string, roleData: IRoleSaveData): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "save_role",
        tab: "team",
        project: projectId || store.getters.project?.id,
        id: roleData.id,
        role: roleData,
      };
      ApiService.request({
        method: 'post',
        data: qs.stringify(params),
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  removeProjectTeamRole: function (projectId: string, roleId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        action: "save_role",
        tab: "team",
        project: projectId || store.getters.project?.id,
        id: roleId,
        role: {
          del: 1
        },
      };
      ApiService.request({
        method: 'post',
        data: qs.stringify(params),
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  },


}
